import React from "react";
import NavBar from "./navbar";
import Footer from "./footer";
import "./css/products.css";
import Whatsapp from "./whatsapp";

function Products() {
  return (
    <>
      <NavBar product={true} />
      <div className="top-image">
        <img src={"/assets/products/top-img.jpg"} alt="img2" />
        <div className="top-image-container">
          <h2>Our Products</h2>
          <p>
            From our creative designers to the expert carpenters, we boast an
            in-house team that understands the importance of luxury and how to
            beautifully hand-craft wooden products that will not only enhance
            the aesthetics of a room but ultimately increase the overall value
            of your property.
          </p>
        </div>
      </div>

      <div className="product-container">
        <div className="product-wood-container" id="wood-section">
          <h3>WOODS</h3>
          <div className="product-wood-grid">
            <div className="product-card">
              <a href="/product/1">
                <img
                  src={require("../assets/Products/Teak-wood.jpg")}
                  alt="img2"
                />
                <h2>Teak Wood</h2>
              </a>
            </div>
            <div className="product-card">
              <a href="/product/2">
                <img
                  src={require("../assets/Products/pine-wood.jpg")}
                  alt="img2"
                />
                <h2>Imported Pinewood </h2>
              </a>
            </div>
            <div className="product-card">
              <a href="/product/3">
                <img
                  src={require("../assets/Products/sal-wood.jpeg")}
                  alt="img2"
                />
                <h2>Sal Wood </h2>
              </a>
            </div>
          </div>
        </div>
        <div className="product-door-container" id="door-section">
          <h3>DOORS</h3>
          <div className="product-wood-grid">
            <div className="product-card">
              <div className="product-card">
                {" "}
                <a href="/product/4">
                  <img
                    src={require("../assets/Products/Doors/Wooden-door.jpg")}
                    alt="img2"
                  />
                  <h2> Designer Wooden Doors </h2>
                </a>
              </div>
            </div>
            <div className="product-card">
              {" "}
              <a href="/product/5">
                <img
                  src={require("../assets/Products/Doors/laminated-door.jpg")}
                  alt="img2"
                />
                <h2> Designer Laminated doors </h2>
              </a>
            </div>
            <div className="product-card">
              {" "}
              <a href="/product/6">
                <img
                  src={require("../assets/Products/Doors/wpc-door.jpg")}
                  alt="img2"
                />
                <h2>WPC & PVC Doors </h2>
              </a>
            </div>
          </div>
        </div>
        <div className="product-frames-container" id="frame-section">
          <h3>FRAMES AND SHUTTERS</h3>
          <div className="product-frame-grid">
            <div className="product-card">
              {" "}
              <a href="/product/7">
                <img
                  src={require("../assets/Products/frames/Door-frames.jpg")}
                  alt="img2"
                />
                <h2>DOOR FRAMES</h2>
              </a>
            </div>
            <div className="product-card">
              <a href="/product/8">
                <img
                  src={require("../assets/Products/frames/window-frames.jpg")}
                  alt="img2"
                />
                <h2>WINDOW FRAMES</h2>
              </a>
            </div>
            <div className="product-card">
              {" "}
              <a href="/product/9">
                <img
                  src={require("../assets/Products/frames/shutters.jpg")}
                  alt="img2"
                />
                <h2>WOODEN SHUTTERS</h2>
              </a>
            </div>
          </div>
        </div>
        <div className="product-pack-container" id="pack-section">
          <h3>PACKAGING</h3>
          <div className="product-pack-grid">
            <div className="product-card">
              {" "}
              <a href="/product/10">
                <img
                  src={require("../assets/Products/Packaging/wood-box.jpg")}
                  alt="img2"
                />
                <h2>BOX</h2>
              </a>
            </div>
            <div className="product-card">
              {" "}
              <a href="/product/11">
                <img
                  src={require("../assets/Products/Packaging/pallets.jpg")}
                  alt="img2"
                />
                <h2>PALLETS</h2>
              </a>
            </div>
            <div className="product-card">
              {" "}
              <a href="/product/12">
                <img
                  src={require("../assets/Products/Packaging/crate.jpg")}
                  alt="img2"
                />
                <h2>CRATES</h2>
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <Whatsapp />
    </>
  );
}

export default Products;
