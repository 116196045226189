import React, { useState } from "react";
import logo from "../assets/logo2.webp";
import marker from "../assets/marker.png";
import "./css/navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faEnvelope,
  faAngleUp,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
function NavBar(prop) {
  const [toggle, settoggle] = useState(false);
  const [product1, setproduct1] = useState(false);
  const [product2, setproduct2] = useState(false);
  const [product3, setproduct3] = useState(false);
  const [product4, setproduct4] = useState(false);

  function changleToggle() {
    settoggle(!toggle);
  }
  function setproduct() {
    setproduct1(!product1);
    setproduct4(false);
    setproduct3(false);
  }
  function setproduct_2() {
    setproduct2(!product2);
    setproduct4(false);
    setproduct1(false);
  }
  function setproduct_3() {
    setproduct1(false);
    setproduct3(!product3);
  }
  function setproduct_4() {
    setproduct1(false);
    setproduct2(false);
    setproduct4(!product4);
  }
  return (
    <>
      <nav className="navbar">
        <a href="/" className="logo-a">
          <img className="logo" src={logo} alt="logo"></img>
        </a>
        <ul className="nav-div">
          <li
            className={
              prop.home ? "navbar-link navbar-link--active" : "navbar-link"
            }
          >
            <a href="/" className="col">
              {" "}
              Home
            </a>
          </li>
          <li
            className={
              prop.about ? "navbar-link navbar-link--active" : "navbar-link"
            }
          >
            <a href="./aboutus" className="col">
              About
            </a>
          </li>
          <li className="drop-down navbar-link ">
            <a
              href="/products"
              className={
                prop.product || prop.item
                  ? "navbar-link--active--product col"
                  : "col"
              }
            >
              Products
            </a>
            <div class="dropdown-content">
              <div className="useability"></div>
              <h5>Woods</h5>
              <ul className="drop-link">
                <li>
                  <a href="/product/1">Teak wood</a>
                </li>
                <li>
                  <a href="/product/2">Imported Pinewood</a>
                </li>
                <li>
                  <a href="/product/3">Sal wood</a>
                </li>
              </ul>
              <h5>Doors</h5>
              <ul className="drop-link">
                <li>
                  <a href="/product/4">Wooden Doors</a>
                </li>
                <li>
                  <a href="/product/5">Designer Laminated Doors</a>
                </li>
                <li>
                  <a href="/product/6">WPC & PVC Doors</a>
                </li>
              </ul>
              <h5>Frames and Shutter</h5>
              <ul className="drop-link">
                <li>
                  <a href="/product/7">Door Frames</a>
                </li>
                <li>
                  <a href="/product/8">Window Frames</a>
                </li>
                <li>
                  <a href="/product/9">Shutters</a>
                </li>
              </ul>
              <h5>Packaging</h5>
              <ul className="drop-link">
                <li>
                  <a href="/product/10">Box</a>
                </li>
                <li>
                  <a href="/product/11">Pallets</a>
                </li>
                <li>
                  <a href="/product/12">Crates</a>
                </li>
              </ul>
            </div>
          </li>
          <li className="navbar-link-contact">
            <a href="/contactus">Contact</a>
          </li>

          <div className="end">
            <a href="tel:9441522211">
            <FontAwesomeIcon icon={faPhoneAlt} /> +91 9441522211/+91 9848441991
            </a>
          </div>
          <a
            href="https://goo.gl/maps/BAnrbVZLG7MhD9z58"
            target="_blank"
            without
            rel="noopener noreferrer"
          >
            <img className="mark" src={marker} alt="makr" />
          </a>
        </ul>

        <button
          className={
            toggle
              ? "hamburger hamburger--elastic is-active"
              : "hamburger hamburger--elastic"
          }
          type="button"
          onClick={changleToggle}
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </nav>
      <div className={toggle ? "toggle-active" : "toggle-not-active"}>
        <div className="toggle-container">
          <div className="product-toggle-bg">
            <a href="/products" id="toggle-highlights">
              Products
            </a>
            <div
              className={
                product1
                  ? "product-dropdown-visible"
                  : "product-dropdown-invisible"
              }
            >
              <button onClick={setproduct} className="door-button">
                <h3 className="product-dp-doors">Woods</h3>
                <FontAwesomeIcon icon={product1 ? faAngleDown : faAngleUp} />
              </button>
              <ul onClick={changleToggle}>
                <a href="/product/1">
                  <li>Teak wood</li>
                </a>
                <a href="/product/2">
                  <li>Imported PineWood</li>
                </a>
                <a href="/product/3">
                  <li>Sal Wood</li>
                </a>
              </ul>
            </div>
            <div
              className={
                product2
                  ? "product-dropdown-visible"
                  : "product-dropdown-invisible"
              }
            >
              <button onClick={setproduct_2} className="door-button">
                <h3 className="product-dp-doors">Doors</h3>
                <FontAwesomeIcon icon={product2 ? faAngleDown : faAngleUp} />
              </button>
              <ul onClick={changleToggle}>
                <a href="/product/4">
                  <li>Wooden Doors</li>
                </a>
                <a href="/product/5">
                  <li>Designer Laminated Doors</li>
                </a>
                <a href="/product/6">
                  <li>WPC & PVC Doors</li>
                </a>
              </ul>
            </div>
            <div
              className={
                product3
                  ? "product-dropdown-visible"
                  : "product-dropdown-invisible"
              }
            >
              <button onClick={setproduct_3} className="door-button">
                <h3 className="product-dp-doors">Frames And Shutters</h3>
                <FontAwesomeIcon icon={product3 ? faAngleDown : faAngleUp} />
              </button>
              <ul onClick={changleToggle}>
                <a href="/product/7">
                  <li>Door Frames</li>
                </a>
                <a href="/product/8">
                  <li>Window Frames</li>
                </a>
                <a href="/product/9">
                  {" "}
                  <li>Shutters</li>
                </a>
              </ul>
            </div>
            <div
              className={
                product4
                  ? "product-dropdown-visible"
                  : "product-dropdown-invisible"
              }
            >
              <button onClick={setproduct_4} className="door-button">
                <h3 className="product-dp-doors">Packaging</h3>
                <FontAwesomeIcon icon={product4 ? faAngleDown : faAngleUp} />
              </button>
              <ul onClick={changleToggle}>
                <a href="/product/10">
                  <li>Box</li>
                </a>
                <a href="/product/11">
                  <li>Pallets</li>
                </a>
                <a href="/product/12">
                  {" "}
                  <li>Crates</li>
                </a>
              </ul>
            </div>
          </div>
          <div className="toggle-contact">
            <a href="/aboutus" id="toggle-highlights">
              About Us{" "}
            </a>
            <div className="contact-toggle-bg">
              <a href="/contactus" id="toggle-highlights">
                Contact Us{" "}
              </a>
              <a href="mailto:ravanitimbers@gmail.com" className="toggle-email">
                <FontAwesomeIcon icon={faEnvelope} /> {"  "}
                ravanitimbers@gmail.com
              </a>
              <a href="tel:9441522211" className="toggle-phone">
              <FontAwesomeIcon icon={faPhoneAlt} />  +91 9441522211
              </a>
              <a href="tel:9848441991" className="toggle-phone">
                <FontAwesomeIcon icon={faPhoneAlt} /> +91 9848441991
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavBar;
