export const PRODUCTS = [
  {
    id: 1,
    title: "Teak Wood",
    category: "wood",
    description:
      "Through our connections, we are in a great position to source various grades of African Teak and supply them in a plethora of sizes and at extremely competitive prices in Hyderabad. Teak wood is one of the most important sources of imported hardwood timber in India. This kind of wood has a high oil content which provides a naturally high resistance to adverse weather conditions and bugs. In fact, it is said to have the highest decay resistance among all natural lumber products. Because of its natural properties like crack-resistance and anti-warping, teak wood has been a sought-after choice for both indoor and outdoor furnitures by the pool, garden etc. Once the wood surface is polished, teak gives a rich and desired finish to you furniture. Many also prefer the Teak wood we supply for Wooden roofings and floorings for their homes in Hyderabad.",
    images: [
      "/assets/products/wood/teak/img-1.jpg",
      "/assets/products/wood/teak/img-2.jpg",
      "/assets/products/wood/teak/img-3.jpg",
      "/assets/products/wood/teak/img-4.jpg",
    ],
    varieties: ["Indian Teak Wood", "African Teak Wood "],
    uses: ["Doors", "Windows"],
  },
  {
    id: 2,
    title: "Imported Pinewood",
    category: "wood",
    description:
      "Pine wood has been used for furniture as far back in time as furniture was made.Today’s pine furniture styles vary from traditional to modern-day and anywhere in between. Pine stands out as a wood type for furniture because it is cheaper than other woods, more readily renewable, and sees a lot of use when it comes to unused furniture pieces, mainly because the light colour and texture make it easy to paint, stain, or alter. If you are looking for a piece of Pinewood furniture to a special size, design or finish/shade, you’re at the right place. We build bespoke Pine furniture in Hyderabad according to your Design specifications, the are made for durability to create a lasting investment. Our services are customer-friendly which means involving you at each step of production and installation.",
    images: [
      "/assets/products/wood/pine/img-1.jpg",
      "/assets/products/wood/pine/img-2.jpg",
      "/assets/products/wood/pine/img-3.jpg",
      "/assets/products/wood/pine/img-4.jpg",
    ],
    uses: ["Furniture's", "Doors", "windows"],
  },
  {
    id: 3,
    title: "Sal Wood",
    category: "wood",
    description:
      "Sal wood is one of the most famous hardwood timber that is found in India, on the banks of the Yamuna, and in Assam, Bengal and Nepal. With a hard, coarse-grained wood that is light in colour when freshly cut, but becomes dark brown with exposure, Sal Wood still remains to be the strongest type of timber found in Hyderabad. Although not well suited to planing and polishing like Teak, Sal wood is very cost-effective and is 50% tougher enough to withstand water and bad weather. This type of wood is used for doors and window frames, wooden beams, window sills, flooring, tool handles etc. It is usually not preferred for shutters and furniture. Sal Wood readily available in a variety of shapes and sizes with us in Hyderabad. We offer seasoned sal wood timber which display better features and give you more satisfaction with the finished products.",
    images: [
      "/assets/products/wood/sal/img-1.jpeg",
      "/assets/products/wood/sal/img-2.jpg",
      "/assets/products/wood/sal/img-3.jpg",
      "/assets/products/wood/sal/img-4.jpg",
    ],
    uses: ["Doors", "Windows"],
  },
  {
    id: 4,
    title: "Wooden Doors",
    category: "door",
    description:
      "DOORS REIMAGINED TO COMPLEMENT YOUR HOME Whether your home or structure is traditional or contemporary, Wooden designer come in a range of crafted, designer looks that will create instant appeal. Our products are ideal for both new constructions as well as replacements during renovation and remodeling.",
    features: [
      "Termite Proof",
      "Seasoned Wood Used",
      "Available in teakwood/ pinewood",
      "Can be made into any design and pattern",
    ],
    uses: ["Bedroom Doors", "Main Doors", "Balcony Doors"],
    images: [
      "/assets/products/doors/wooden/img-1.jpg",
      "/assets/products/doors/wooden/img-2.jpg",
      "/assets/products/doors/wooden/img-3.jpg",
      "/assets/products/doors/wooden/img-4.jpg",
    ],
  },
  {
    id: 5,
    title: "Laminated Doors",
    category: "door",
    description:
      "The doors in this range are water-proof and covered with High Pressure Laminate (HPL) sheets which give them their distinctively beautiful finish. Carved from the best raw materials available and cut by latest cutting-edge machines, these all-weather doors are capable of withstanding the harshest of climatic conditions, without even a scratch to their exquisiteness.Choose from an exotic range of ravani's Laminates, which uses a unique technology in which special resins impart extra strength to its laminates, making them highly resistant to scratch and abrasion.",
    features: [
      "Ready to Use",
      "Easy to Clean",
      "100% Termite and Borer Proof",
      "Stain Resistant",
    ],
    uses: ["Bedroom Doors"],
    images: [
      "/assets/products/doors/laminated/img-1.jpg",
      "/assets/products/doors/laminated/img-2.jpg",
      "/assets/products/doors/laminated/img-3.jpg",
      "/assets/products/doors/laminated/img-4.jpg",
    ],
  },
  {
    id: 6,
    title: "WPC and PVC",
    category: "door",
    description:
      "Unlike wooden or other kind of Door frames, WPC Door Frames are waterproof, distortion resistant, and have great adaptability to outdoor conditions. WPC is surely a game-changer in the history of Door frames, and in a few years' time, it will be the most appreciated product in the category.",
    features: ["Water Proof", "Termite Proof"],
    uses: ["Toilet And Bathrooms"],
    images: [
      "/assets/products/doors/pvc/img-1.jpg",
      "/assets/products/doors/pvc/img-2.jpg",
      "/assets/products/doors/pvc/img-3.jpg",
      "/assets/products/doors/pvc/img-4.jpg",
    ],
  },

  {
    id: 7,
    title: "Door Frames",
    category: "frames",
    head: "FRAMES",
    description:
      "The Door Frames we manufacture are hand-crafted as per your requirements and the measurements and made to reach you on time for the installation. We offer ready to assemble door frames too which makes the onsite work very much minimal. Our door frames come in sets of Solid wood panels, which are painted and polished on all sides. Each door frame is marked appropriately for easy identification. We provide door frames made out of Teak Wood, Sal Wood and Matti Wood, in Hyderabad. We can produce frames for either single or double opening doors which will always add that finishing touch to any room and will complement and enhance the overall look of the doors.",
    images: [
      "/assets/products/frames/door-frames/img-1.jpg",
      "/assets/products/frames/door-frames/img-2.jpg",
      "/assets/products/frames/door-frames/img-3.jpg",
      "/assets/products/frames/door-frames/img-4.jpg",
    ],
    items: ["Teak Wood Frames", "Aftrican Wood Frames", "WPC & PVC Frames"],
  },
  {
    id: 8,
    title: "Window Frames",
    category: "frames",
    head: "FRAMES",

    description:
      "When you have a scenic view in front of your window, why not invest in one of the most aesthetically pleasing ways to frame a window. Window frames made of natural wood not only lend an attractive and natural appeal to your windows but also serve longer than uPVC and plastic that are brittle. The use of wood in your window frames may also serve as a natural insulator that make your home less-affected from the extreme climatic conditions outside. The most important point is that choosing a wooden window frame is a more environmentally responsible way to show that you care for your home. Reach out to us if you plan on upgrading the look of your windows with classy and eco-friendly wooden window frames.",
    images: [
      "/assets/products/frames/window-frames/img-1.jpg",
      "/assets/products/frames/window-frames/img-2.jpg",
      "/assets/products/frames/window-frames/img-3.jpg",
      "/assets/products/frames/window-frames/img-4.jpg",
    ],
    items: ["Teak Wood Frames", "Aftrican Wood Frames", "WPC & PVC Frames"],
  },
  {
    id: 9,
    title: "Shutters",
    category: "frames",
    head: "SHUTTERS",

    description:
      "Our interior wood shutters offer an affordable and stylish basswood shutter alternative to other window treatment options, a most inexpensive way to get that tropical look. Our interior wood shutters are safe and smart for your home, even if you are not on a budget, wood shutters offer an easy-to-care. They're durable, and for those with young children, they offer an option that is easily cleaned. They reduce heating and air conditioning costs and at the same time reduce outside noise. Wood shutters combine the traditional warmth and beauty of natural wood with the practical advantages of high-tech materials and design. From the traditional to the contemporary, you can express your style with our shutters. Now you can add a personal touch of elegance to any room at the prices designed for any budget. Dusting and cleaning with any wood friendly product that is Non-abrasive and does not contain ammonia",
    images: [
      "/assets/products/shutters/img-1.jpg",
      "/assets/products/shutters/img-2.jpg",
      "/assets/products/shutters/img-3.jpg",
      "/assets/products/shutters/img-4.jpg",
    ],
    items: [
      "Teak Wood Shutters",
      "Aftrican Wood Shutters",
      "WPC & PVC Shutters",
    ],
  },
  {
    id: 10,
    title: "Box",
    category: "packaging",
    description:
      "Providing you the best range of packing boxes, wooden packaging box, industrial wooden packaging boxes, these boxes are designed and developed with the aid of modern machinery and technology installed at our premises. Without compromising with the quality of these boxes, we have mustered huge client-base.",
    features: [
      "Available in different sizes and shapes",
      "Durable",
      "User friendly",
    ],
    images: [
      "/assets/products/packaging/box/img-1.jpg",
      "/assets/products/packaging/box/img-2.jpg",
      "/assets/products/packaging/box/img-3.jpg",
      "/assets/products/packaging/box/img-4.jpg",
    ],
  },
  {
    id: 11,
    title: "Pallets",
    category: "packaging",
    description:
      "Wooden Pallets offered feature quality finish standards and are made available by us in different sizes, design shapes and finish dimensions to choose from. Manufactured using premium grade wood, these pallets deliver longer functional life and also come chemically treated that provides suitable resistance against termites. The superior construction standards of these pallets make these perfect for packaging goods in different capacities.",
    features: [
      "Available in different sizes and shapes",
      "Durable",
      "Can be available in custom specifications",
    ],
    images: [
      "/assets/products/packaging/pallets/img-1.jpg",
      "/assets/products/packaging/pallets/img-2.jpg",
      "/assets/products/packaging/pallets/img-3.jpg",
      "/assets/products/packaging/pallets/img-4.jpg",
    ],
  },
  {
    id: 12,
    title: "Crates",
    category: "packaging",
    description:
      "We are being acknowledged for providing our clients with the finest range of Packaging Wooden Crates. These offered crates are manufactured by making use of supreme quality wood in accordance with the defined parameters of the industry. Furthermore, these boxes are highly acclaimed for their several unsurpassed features such as durability, dimensionally accurateness, fine-finish and high tensile strength.",
    features: [
      "Available in different sizes and shapes",
      "Durable",
      "Can be available in custom specifications",
    ],
    images: [
      "/assets/products/packaging/crates/img-1.jpg",
      "/assets/products/packaging/crates/img-2.jpeg",
      "/assets/products/packaging/crates/img-3.jpg",
      "/assets/products/packaging/crates/img-4.jpg",
    ],
  },
];
