import React from "react";
import Home from "./Components/home";
import Contact from "./Components/contact";
import About from "./Components/about";
import Product from "./Components/products";
import Item from "./Components/item";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import ScrollRestoration from "react-scroll-restoration";
function App() {

  return (
    <>
      <Router>
        <ScrollRestoration />
        <Switch>
          <Route path="/Aboutus" component={About} />
          <Route path="/Contactus" component={Contact} />
          <Route path="/products" component={Product} />
          <Route path="/product/:id" component={Item} />
          <Route path="/" component={Home} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
