import React from "react";
import WaIcon from "../assets/whatsapp.svg";
export default function Whatsapp(prop) {
  if (prop.comp === true) {
    return (
      <div
        className={
          document.body.scrollTop > 10 ||
          document.documentElement.scrollTop > 10
            ? "whatsapp"
            : " whatsapp wa-hide"
        }
      >
        <a
          href="https://api.whatsapp.com/send?phone=+919441522211&text=Hello, Ravani Timbers "
        >
          <img src={WaIcon} alt="wa" />
        </a>
      </div>
    );
  } else {
    return (
      <div className="whatsapp">
        <a
          href="https://api.whatsapp.com/send?phone=+919441522211&text=Hello, Ravani Timbers "
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={WaIcon} alt="wa" />
        </a>
      </div>
    );
  }
}
