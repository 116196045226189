import React from "react";
import NavBar from "./navbar";
import Footer from "./footer";
import "./css/about.css";
import { AboutSection,AboutSection1 } from "./home";
import Fade from "react-reveal/Fade";
import Whatsapp from './whatsapp'
function About() {
  return (
    <>
      <NavBar about={true} />
      <AboutSection home={false} />
      <AboutSection1 home={false}/>
      <div className="history">
        <h3>Our Journey</h3>
        <p>
          <Fade bottom>
            It is the timber of poetry that wears most surely, and there is no
            timber that has not strong roots among the clay and worms. Ravani
            Timbers is one of the best and most trusted woodsmen. It was founded
            by Shivji Ravani in the late 1996 - early 1997. Like
            all companies we started small. We used to cut logs and woods. The
            journey wasn't easy but it was adventorous. We expanded and scaled
            real quick within the market after the launch of window frames. The
            teakwood was a hit in the marketplace and so we also added teakwood
            doors. Packaging boxes, plates, and basically anything made of wood
            was our next ambition which got fulfilled. We didn't want to be
            local and so we started a range in which designer doors and
            laminated doors is a major part of the business we do. We are now
            planning to go from regional to national. Your support is priceless
            and ofcourse keep an eye for major discounts.
          </Fade>
        </p>
      </div>
      <Footer />
      <Whatsapp/>
    </>
  );
}

export default About;


