import React, { useState, useEffect } from "react";
import Footer from "./footer";
import NavBar from "./navbar";
import "./css/item.css";
import { useParams } from "react-router-dom";
import { PRODUCTS } from "../shared/products";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Wood from "../assets/Products/wood.svg";
import Whatsapp from "./whatsapp";

export default function Item() {
  const { id } = useParams();
  const [product, setproduct] = useState([0]);
  useEffect(() => {
    const setdata = () => {
      setproduct(PRODUCTS.filter((item) => item.id == id)[0]);
    };
    setdata();
  }, [id]);
  if (product.images != undefined) {
    var img0 = product.images[0];
    var img1 = product.images[1];
    var img2 = product.images[2];
    var img3 = product.images[3];
  }
  return (
    <>
      <NavBar item={true} />
      <div className="product-page">
        <div className="product-page-container">
          <div className="img-container">
            <Carousel
              autoPlay={false}
              thumbWidth={30}
              showIndicators={false}
              showThumbs={true}
              showStatus={false}
              showArrows={false}
              swipeable={true}
            >
              <div className="item-image">
                <img src={img0} alt="img2" />
              </div>
              <div className="item-image">
                <img src={img1} alt="img2" />
              </div>
              <div className="item-image">
                <img src={img2} alt="img2" />
              </div>
              <div className="item-image">
                <img src={img3} alt="img2" />
              </div>
            </Carousel>
          </div>
          <div className="item-details">
            <Woods product={product} />
            <Door product={product} />
            <Frames product={product} />
            <Packaging product={product} />
          </div>
        </div>
      </div>
      <Footer />
      <Whatsapp />
    </>
  );
}
function Woods(prop) {
  console.log(prop.product.category);
  if (prop.product.category === "wood") {
    return (
      <>
        <h3>{prop.product.title}</h3>
        <p>{prop.product.description}</p>
        <h4 id="feature-head" className={prop.product.id == 1 ? "" : "var-vis"}>
          Varieties
        </h4>

        <Varieties varieties={prop.product.varieties} id={prop.product.id} />
        <h4 id="feature-head">Uses</h4>
        <ul>
          <Uses uses={prop.product.uses} />
        </ul>
      </>
    );
  } else {
    return null;
  }
}
function Door(prop) {
  if (prop.product.category === "door") {
    return (
      <>
        <h3>{prop.product.title}</h3>
        <p>{prop.product.description}</p>
        <h4 id="feature-head">Features</h4>
        <ul>
          <Feature features={prop.product.features} />
        </ul>
        <h4 id="feature-head">Uses</h4>
        <ul>
          <Uses uses={prop.product.uses} />
        </ul>
      </>
    );
  } else {
    return null;
  }
}

function Frames(prop) {
  if (prop.product.category === "frames") {
    return (
      <>
        <h3>{prop.product.title}</h3>
        <p>{prop.product.description}</p>
        <h4>{prop.product.head} WE OFFER</h4>
        <div className="frame-row">
          <div className="frame-item">
            {" "}
            <img src={require("../assets/Products/Teak-wood.jpg")} alt="img2" />
            <h2>{prop.product.items[0]}</h2>
          </div>
          <div className="frame-item">
            <img
              src={require("../assets/Products/afkteak-wood.png")}
              alt="img2"
            />
            <h2>{prop.product.items[1]}</h2>
          </div>
          <div className="frame-item">
            {" "}
            <img
              src={require("../assets/Products/frames/wpc-frame.jpg")}
              alt="img2"
            />
            <h2>{prop.product.items[2]}</h2>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}
function Packaging(prop) {
  if (prop.product.category === "packaging") {
    return (
      <>
        <h3>{prop.product.title}</h3>
        <p>{prop.product.description}</p>
        <h4 id="feature-head">Features</h4>
        <ul>
          <Feature features={prop.product.features} />
        </ul>
      </>
    );
  } else {
    return null;
  }
}

function Feature(prop) {
  return prop.features.map((feature) => {
    return (
      <li className="features-list">
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM15.2929 8.29289L10 13.5858L7.70711 11.2929L6.29289 12.7071L10 16.4142L16.7071 9.70711L15.2929 8.29289Z"
            fill="#e3a44e"
          />
        </svg>
        {feature}
      </li>
    );
  });
}
function Uses(prop) {
  return prop.uses.map((use) => {
    return (
      <li className="uses-list">
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM15.2929 8.29289L10 13.5858L7.70711 11.2929L6.29289 12.7071L10 16.4142L16.7071 9.70711L15.2929 8.29289Z"
            fill="#e3a44e"
          />
        </svg>
        {use}
      </li>
    );
  });
}

function Varieties(prop) {
  console.log(prop.varieties);
  if (prop.id == 1) {
    return prop.varieties.map((item) => {
      return (
        <li className="feat-li">
          <img src={Wood} alt="img"></img>
          {item}
        </li>
      );
    });
  } else {
    return null;
  }
}
